export default [
  {
    name: "SummaryToTalTrans",
    path: "/biz/bep/agg/SummaryToTalTrans",
    component: () => import("@/views/biz/bep/agg/SummaryToTalTrans"),
    meta: { layout: "admin" }
  },
  // {
  //   name: "CashierMng",
  //   path: "/biz/bep/svc/CashierMng",
  //   component: () => import("@/views/biz/bep/svc/CashierMng"),
  //   meta: { layout: "admin" } 
  // },-

  // {
  //   name: "AbbankMerCmmsCompr",
  //   path: "/biz/bep/svc/AbbankMerCmmsCompr",
  //   component: () => import("@/views/biz/bep/svc/AbbankMerCmmsCompr"),
  //   meta: { layout: "admin" } 
  // },
  {
    name: "MerPcPosMapping",
    path: "/biz/bep/merinst/MerPcPosMapping",
    component: () => import("@/views/biz/bep/merinst/MerPcPosMapping"),
    meta: { layout: "admin" }
  },

  {
    name: "MerEpayMapping",
    path: "/biz/bep/merinst/MerEpayMapping",
    component: () => import("@/views/biz/bep/merinst/MerEpayMapping"),
    meta: { layout: "admin" }
  }
] 